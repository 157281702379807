<template>
  <div>
    <b-modal
      id="modal-add-lov-size"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddSize')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.name"
          />
        </b-form-group>

        <b-form-group />
        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      form: {
        name: null,
      },
    }
  },
  methods: {
    onSubmit() {
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      this.$emit('addSize', this.form)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.name = null
    },
  },
}
</script>

<style>

</style>
