<template>
  <div>
    <b-modal
      id="modal-edit-category"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditCategory')"
      hide-footer
      @show="onShow"
      @click="onReset"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.name"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group />

        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onReset"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: ['category'],
  data() {
    return {
      form: {
        name: null,
        id: null,
      },
    }
  },
  watch: {
    category: {
      handler(newValue) {
        this.form.name = newValue.name;
        this.form.id = newValue.lovDressCategoryId;
      },
      immediate: true,
    },
  },
  methods: {
    onShow() {
      this.form.name = this.category.name;
      this.form.id = this.category.lovDressCategoryId;
    },
    onSubmit() {
      this.$emit('updateCategory', this.form);
      this.$refs.modal.hide();
    },
    onReset() {
      this.$refs.modal.hide();
      this.form = {
        name: null,
        lovDressCategoryId: null,
      };
    },
  },
}
</script>
