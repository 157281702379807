<template>
  <div
    class="main"
    style="display: flex; flex-wrap: wrap;"
  >
    <!-- Category Tab -->
    <div style="flex: 1; margin-right: 10px;">
      <b-tabs>
        <b-tab :title="$t('Category')">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Category") }}</strong>
          </template>
          <button
            v-b-modal.modal-add-category
            class="button"
            style="margin-top: 1%"
          >
            {{ $t('AddCategory') }}
          </button>
          <div
            style="width: 100%; margin-top: 15px; height: 75vh; overflow-y: scroll"
            class="scroll"
          >
            <table
              class="team_table"
              style="width: 100%"
            >
              <thead style="position: sticky; top: -17px">
                <tr>
                  <th>{{ $t('CategoryName') }}</th>
                  <th>{{ $t('Edit') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category,index) in getDressCategory"
                  :key="index"
                >
                  <td>{{ category.name }}</td>
                  <td
                    v-b-modal.modal-edit-category
                    @click="editProdCategory(category)"
                  >
                    <b-icon-pencil />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
      <AddCategory @addCategory="addCategories" />
      <EditCategory
        :category="category"
        @updateCategory="editCategories"
      />
    </div>

    <!-- Size Tab -->
    <div style="flex: 1; margin-left: 10px;">
      <b-tabs>
        <b-tab :title="$t('Size')">
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("Sizes") }}</strong>
          </template>
          <button
            v-b-modal.modal-add-lov-size
            class="button"
            style="margin-top: 1%"
          >
            {{ $t('AddSize') }}
          </button>
          <div
            style="width: 100%; margin-top: 15px; height: 75vh; overflow-y: scroll"
            class="scroll"
          >
            <table
              class="team_table"
              style="width: 100%"
            >
              <thead style="position: sticky; top: -17px">
                <tr>
                  <th>{{ $t('SizeName') }}</th>
                  <th>{{ $t('Edit') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(size,index) in getProductSize"
                  :key="index"
                >
                  <td>{{ size.name }}</td>
                  <td
                    v-b-modal.modal-edit-luv-size
                    @click="editProdSize(size)"
                  >
                    <b-icon-pencil />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
      <AddLovSize @addSize="addSizes" />
      <EditLovSize
        :size="size"
        @updateSize="editSize"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddCategory from '@/components/products/modals/AddCategory.vue'
import AddLovSize from '@/components/products/modals/AddLovSize.vue'
import EditCategory from '@/components/products/modals/EditCategory.vue'
import EditLovSize from '@/components/products/modals/EditLovSize.vue'

export default {
  components: {
    AddCategory,
    AddLovSize,
    EditCategory,
    EditLovSize,
  },
  data() {
    return {
      category: {
        name: null,
        lovDressCategoryId: null,
      },
      size: {
        name: null,
        sizeDataId: null,
      },
    }
  },
  computed: {
    ...mapGetters(['getDressCategory', 'getProductSize']),
  },
  mounted() {
    this.dressCategories()
    this.productSizes()
  },
  methods: {
    ...mapActions(['editLuvSize', 'editCategory', 'dressCategories', 'productSizes', 'addProductCategory', 'addProductLovSize']),
    async addCategories(obj) {
      await this.addProductCategory({
        object: obj,
        successCallback: () => {
          this.dressCategories()
        },
      })
    },
    async addSizes(obj) {
      await this.addProductLovSize({
        object: obj,
        successCallback: () => {
          this.productSizes()
        },
      })
    },
    async editCategories(obj) {
      await this.editCategory({
        object: obj,
        successCallback: () => {
          this.dressCategories()
        },
      })
    },
    async editSize(obj) {
      await this.editLuvSize({
        object: obj,
        successCallback: () => {
          this.productSizes()
        },
      })
    },
    editProdCategory(category) {
      this.category.name = category.name;
      this.category.lovDressCategoryId = category.lovDressCategoryId;
      this.$bvModal.show('modal-edit-category');
    },
    editProdSize(size) {
      this.size.name = size.name;
      this.size.sizeDataId = size.sizeDataId;
      this.$bvModal.show('modal-edit-luv-size');
    },
  },
}
</script>

<style>
.team_table td {
  padding: 16px 9px !important;
}
</style>
