<template>
  <div
    style="margin-top: 10px;"
  >
    <ProductConfig />
  </div>
</template>

<script>
import ProductConfig from '@/components/products/ProductConfig.vue'

export default {
  components: { ProductConfig },
}
</script>

<style>
@media screen and (min-width: 1440px) and (max-width: 1520px) {
  .main{
    padding: 80px 15px 15px 90px;
  }
}

@media screen and (max-width: 1440px){
  .main{
    padding: 75px 15px 15px 80px;
  }
}

@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1200px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
    justify-content: center;
  }
}
</style>
